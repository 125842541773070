import React from 'react';
import { unstable_createRoot as createRoot } from 'react-dom';
import { useDropzone } from 'react-dropzone';
import { MailIcon } from '@primer/octicons-react';
import FileReader from '@tanker/file-reader';
import { toEmailAddress, build } from 'eml-format-goon';
import { saveAs } from 'file-saver';
import { extname, basename } from 'path-browserify';
import { Headers } from 'messy';
import { lookup } from 'mime-types';
import { encode } from 'uint8-to-base64';

import {
  Msg,
  PidTagSubject,
  PidTagSenderName,
  PidTagSenderEmailAddress,
  PidTagSentRepresentingName,
  PidTagBody,
  PidTagMessageDeliveryTime,
  PidTagTransportMessageHeaders,
  PidTagAttachFilename,
} from 'msg-parser';

const Styles = {
  input: {
    display: 'none',
  },
  group: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  container: {
    paddingLeft: 24,
    paddingRight: 24,
  },
};

const Application = () => {
  const handleDrop = React.useCallback(async (files) => {
    for await (const file of files) {
      const { name } = file;
      const reader = new FileReader(file);
      const msg = Msg.fromUint8Array(await reader.readAsArrayBuffer());

      const headers = new Headers(
        msg.getProperty(PidTagTransportMessageHeaders),
      );

      const attachments = msg.attachments().map((attachment) => {
        const name = attachment.getProperty(PidTagAttachFilename);

        return {
          name,
          contentType: lookup(name),
          data: encode(attachment.content()),
        };
      });

      const eml = await new Promise((resolve, reject) => {
        return build(
          {
            date: msg.getProperty(PidTagMessageDeliveryTime),
            from: toEmailAddress({
              name:
                msg.getProperty(PidTagSentRepresentingName) ||
                msg.getProperty(PidTagSenderName),
              email: msg.getProperty(PidTagSenderEmailAddress),
            }),
            to: headers.get('to'),
            cc: headers.get('cc'),
            bcc: headers.get('bcc'),
            subject: msg.getProperty(PidTagSubject),
            text: msg.getProperty(PidTagBody),
            // html: msg.getProperty(PidTagRtfCompressed),
            attachments,
          },
          (err, ...args) => (err ? reject(err) : resolve(...args)),
        );
      });

      saveAs(
        new Blob([eml], { type: 'text/plain' }),
        basename(name, extname(name)) + '.eml',
      );
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
  });

  return (
    <div
      className="blankslate blankslate-large blankslate-spacious"
      style={Styles.container}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <MailIcon
        className="octicon octicon-octoface blankslate-icon"
        size={96}
      />
      <h3 className="mb-1">MSG to EML.</h3>
      <p>Drop files here or click to upload.</p>
    </div>
  );
};

createRoot(document.getElementById('root')).render(<Application />);
